import { useMutation, useQuery } from '@apollo/client'
import { TableCell, TextField } from '@material-ui/core'
import { AssignmentReturnRounded } from '@material-ui/icons'
import { isNil, uniq } from 'ramda'
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import {
  GetProductDuplicateSupplier,
  Mutation,
  MutationUpdateProductDuplicateArgs,
  Query,
} from 'api'
// import { EnhancedTable } from 'components'
import { Autocomplete, Button } from 'components'
import { EnhancedTable } from 'components/EnhancedTable'
import { useTitle } from 'contexts'

import {
  createProductDuplicatesListMutation,
  getProductDuplicateQuery,
  updateProductDuplicateMutation,
} from './graphql'
import { useStyles } from './styles'

export const ProductDuplicates = () => {
  const classes = useStyles()
  const [stateData, setStateData] = useState<any[]>([])
  const [primaryDuplicate, setPrimaryDuplicate] = useState<number>()
  const [
    orignalPrimaryDuplicate,
    setOrignalPrimaryDuplicate,
  ] = useState<number>()
  const [duplicate, setDuplicate] = useState<number>()
  const [productMergeId, setProductMergeId] = useState<number>()
  const [supplierId, setSupplierId] = useState<number>()
  const [suppliers, setSuppliers] = useState<GetProductDuplicateSupplier[]>()
  const { setTitle } = useTitle()

  useEffect(() => {
    setTitle('Product Matches')
  }, [])

  const { data, loading, error, refetch } = useQuery<{
    getProductDuplicate: Query['getProductDuplicate']
    getProductDuplicateListSummary: Query['getProductDuplicateListSummary']
    getProductDuplicateSuppliers: Query['getProductDuplicateSuppliers']
  }>(getProductDuplicateQuery, {
    variables: {
      supplierId,
    },
  })

  useEffect(() => {
    if (data?.getProductDuplicateListSummary?.matchesRemaining === 0) {
      setSupplierId(undefined)
      setStateData([])
      setProductMergeId(undefined)
      toast.success(`There are no more matches for this supplier or list`)
    } else {
      if (data?.getProductDuplicate) {
        const merges = []
        const primaryProduct = data?.getProductDuplicate.primaryProduct
        const duplicateProduct = data?.getProductDuplicate.duplicateProduct
        const primaryHref = `/products/edit/${primaryProduct.id}`
        const duplicateHref = `/products/edit/${duplicateProduct.id}`

        /* eslint-disable */
        merges.push({
          code: primaryProduct.code,
          name: primaryProduct.name,
          packSize: primaryProduct.packSize,
          unitValue: primaryProduct.unitValue,
          unit: primaryProduct.unit.name,
          price: primaryProduct.price,
          supplierName: primaryProduct.supplier.name,
          status: primaryProduct.status,
          productLink: (
            <a href={primaryHref} target="_blank">
              Product Link
            </a>
          ),
          id: primaryProduct.id,
          updatedAt: primaryProduct.updatedAt,
          rowClass: primaryProduct.status === 'available',
        })
        merges.push({
          code: duplicateProduct.code,
          name: duplicateProduct.name,
          packSize: duplicateProduct.packSize,
          unitValue: duplicateProduct.unitValue,
          unit: duplicateProduct.unit.name,
          price: duplicateProduct.price,
          supplierName: duplicateProduct.supplier.name,
          status: duplicateProduct.status,
          productLink: (
            <a href={duplicateHref} target="_blank">
              Product Link
            </a>
          ),
          id: duplicateProduct.id,
          updatedAt: duplicateProduct.updatedAt,
          rowClass: duplicateProduct.status === 'available',
        })
        /* eslint-enable */

        if (
          data?.getProductDuplicateSuppliers &&
          data?.getProductDuplicateSuppliers.length > 0
        ) {
          //@ts-ignore
          setSuppliers(data?.getProductDuplicateSuppliers)
        }
        setPrimaryDuplicate(primaryProduct.id)
        setOrignalPrimaryDuplicate(primaryProduct.id)
        setDuplicate(duplicateProduct.id)
        setProductMergeId(data?.getProductDuplicate.id)
        setStateData(merges)
      }
    }
  }, [data])

  const [fetch, { loading: mutationloading }] = useMutation<
    { updateProductDuplicate: Mutation['updateProductDuplicate'] },
    MutationUpdateProductDuplicateArgs
  >(updateProductDuplicateMutation)

  const [
    fetchCreateDuplicateList,
    { loading: mutationLoadingCreateDuplicateList },
  ] = useMutation<{
    createProductDuplicatesList: Mutation['createProductDuplicatesList']
  }>(createProductDuplicatesListMutation)

  const handleCreateDuplicateList = () => {
    fetchCreateDuplicateList()
      .then(({ data, errors }) => {
        if (isNil(data)) {
          toast.error(JSON.stringify(errors))
          return
        }
        refetch()
        toast.success(
          `Successfully created list of ${data.createProductDuplicatesList} matches`,
        )
      })
      .catch(() => {
        toast.error('Error')
      })
  }

  const handleMerge = (merge: boolean) => {
    if (productMergeId && primaryDuplicate && duplicate) {
      fetch({
        variables: {
          diffPrimaryId: !(orignalPrimaryDuplicate === primaryDuplicate),
          duplicateId: duplicate,
          merge,
          primaryId: primaryDuplicate,
          productMergeId,
        },
      })
        .then(({ data, errors }) => {
          if (isNil(data)) {
            toast.error(JSON.stringify(errors))
            return
          }
          refetch()
          if (merge) {
            toast.success(`Successfully merged`)
          } else {
            toast.success(`Successfully marked as not a duplicate`)
          }
        })
        .catch(() => {
          toast.error('Error')
        })
    }
  }

  const handleUpdateDuplicates = (id: number, primary: boolean) => {
    if ((primary && id === primaryDuplicate) || (!primary && id === duplicate))
      return

    if (primary) {
      const oldPrimaryDuplicate = primaryDuplicate
      setPrimaryDuplicate(id)
      setDuplicate(oldPrimaryDuplicate)
      return
    }

    if (!primary) {
      const oldDuplicate = duplicate
      setPrimaryDuplicate(oldDuplicate)
      setDuplicate(id)
      return
    }
  }

  return (
    <React.Fragment>
      <p>
        Matches Remaining:{' '}
        {data?.getProductDuplicateListSummary?.matchesRemaining}
      </p>
      <p>
        Matches Checked: {data?.getProductDuplicateListSummary?.matchesChecked}
      </p>
      <p>Total: {data?.getProductDuplicateListSummary?.total}</p>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        loading={
          loading || mutationloading || mutationLoadingCreateDuplicateList
        }
        style={{ marginBottom: 20 }}
        onClick={() => handleCreateDuplicateList()}>
        Create List of Matches
      </Button>
      <Autocomplete
        style={{ width: 500 }}
        options={suppliers ?? []}
        autoHighlight
        disabled={loading}
        value={suppliers?.find((x) => x.id === supplierId)}
        getOptionLabel={(option) => `${option?.name}(${option?.count})`}
        onChange={(_event, newValue) => {
          setSupplierId(newValue?.id)
          refetch()
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Supplier"
            variant="outlined"
            disabled={loading}
            // inputProps={{
            //   ...params.inputProps,
            //   autoComplete: 'new-password',
            // }}
          />
        )}
      />
      <EnhancedTable
        data={stateData}
        setNewParams={() => null}
        total={stateData.length}
        loading={loading}
        title="Matches"
        actionHeader="Actions"
        hidePagination
        actions={(id: number, name: string, item: unknown) => {
          // const product = item as Product
          return (
            <>
              <TableCell>
                <Button
                  // disabled={inactive}
                  // data-testid="exportButton"
                  //   loading={exportloading}
                  style={{ fontSize: 16, marginRight: 10 }}
                  aria-label="parent"
                  onClick={() => {
                    handleUpdateDuplicates(id, true)
                  }}>
                  <p
                    style={{
                      color:
                        primaryDuplicate === id ? 'rgb(92, 184, 92)' : 'red',
                      fontWeight: 600,
                    }}>
                    P
                  </p>
                </Button>
                <Button
                  // disabled={inactive}
                  // data-testid="exportButton"
                  //   loading={exportloading}
                  style={{ fontSize: 16, marginRight: 10 }}
                  aria-label="parent"
                  onClick={() => {
                    handleUpdateDuplicates(id, false)
                  }}>
                  <p
                    style={{
                      color: duplicate === id ? 'rgb(92, 184, 92)' : 'red',
                      fontWeight: 600,
                    }}>
                    D
                  </p>
                </Button>
              </TableCell>
            </>
          )
        }}
        rowClass={classes.row}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={!primaryDuplicate && !duplicate}
        loading={
          loading || mutationloading || mutationLoadingCreateDuplicateList
        }
        style={{ marginRight: 10 }}
        onClick={() => handleMerge(true)}>
        Merge
      </Button>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={!primaryDuplicate && !duplicate}
        loading={
          loading || mutationloading || mutationLoadingCreateDuplicateList
        }
        // className={classes.merge}
        onClick={() => handleMerge(false)}>
        Not Duplicate
      </Button>
    </React.Fragment>
  )
}
