export const PRIMARY = '#20314a'
export const SECONDARY = '#f5d94e'

export const jellyBlue = '#1F304A'
export const jellyRed = '#FF2B30'
export const jellyYellow = '#F5D94F'

export const DARK_GREY = '#4c596e'
export const GREY = '#a5acb7'
export const panelBackgroundColour = '#f5f5f5'

export const FORM_GREY = 'rgb(155, 155, 155)' // #9b9b9b
