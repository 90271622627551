import { createMuiTheme } from '@material-ui/core'

import { PRIMARY, SECONDARY } from './colors'

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: PRIMARY,
    },
    secondary: {
      main: SECONDARY,
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  typography: {
    fontFamily: 'Rubik, sans-serif',
    fontSize: 16,
  },
})
